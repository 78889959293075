import { BunnyLoader } from "@local/utils/src/bunnyLoader";
import Image from "next/image";
export const CenterForWomenBanner = () => {
  return <div className="bg-[#753d7d] py-8 sm:py-16 px-4" data-sentry-component="CenterForWomenBanner" data-sentry-source-file="CenterForWomenBanner.tsx">
      <div className="container mx-auto flex flex-col sm:flex-row text-white max-w-4xl gap-8">
        <div className="bg-[#eacbee] p-4 rounded-lg flex flex-col align-middle items-center justify-center shrink-0 grow">
          <Image src="/images/center-for-women-and-families-logo.png" width={240} height={120} alt="Center for Womem and Families logo" loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} data-sentry-element="Image" data-sentry-source-file="CenterForWomenBanner.tsx" />
        </div>
        <div className="space-y-2 flex flex-col align-middle items-center justify-center">
          <h5 className="text-xl md:text-3xl font-bold">
            Hyper Arch Motion Supports The Center for Women and Families.
          </h5>
          <p className="font-light">
            The Center for Women and Families provides trauma-informed advocacy
            and support for individuals, families and communities affected by
            intimate partner violence and sexual assault. We also offer
            assistance to family, friends, and partners close to survivors of
            intimate partner violence and sexual assault. We mitigate the impact
            of trauma by building resiliency and reducing risk factors at all
            levels. We are guided by a strong grassroots feminist history, and a
            commitment to social and economic justice. We co-create violence
            free lives, families, and communities.
          </p>
        </div>
      </div>
    </div>;
};
export default CenterForWomenBanner;